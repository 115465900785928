@import "../../../assets/styles/variables";

.grants {
  height: 100%;
  >div {
    height: 100%;
  }
  .header {
    padding-bottom: 20px;
    justify-content: space-between;
  }
  .actions {
    justify-content: end;
  }
}
