@import "../../assets/styles/variables";

.users-page {
  color: $form_color;
  .header {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .table {
    margin: 10px 20px;
    color: $form_color;
  }
  .back-to {
    padding-top: 10px;
    display: flex;
    align-items: center;
    a {
      font-size: 15px;
    }
  }
}
