.grant-dashboard {
  .header {
    .right-side {
      display: flex;
      align-items: center;
    }
  }
  .sub-header {
    div {
      min-width: 100px;
    }
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    .filter {
      display: flex;
      a {
        cursor: pointer;
        &.active {
          text-decoration: none;
          color: #000;
        }
      }
    }
    .period-controls {
      display: flex;
      align-items: center;
    }
  }
}
