.user-menu {
  display: flex;
  align-items: center;
  .dropdown {
    border-radius: 50%;
    background-image: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAIAAgAMBIgACEQEDEQH/xAAbAAEAAgMBAQAAAAAAAAAAAAAAAwUBAgQGB//EACwQAAICAQEHAwQCAwAAAAAAAAABAgMEEQUTITFBUXEiMmESQlKBIzOCkdH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAMAwEAAhEDEQA/APuIAAAAAAAAIbMmiv32x8LiQvaOOnwcn/iB2A41tHHfNyXmJNXk0WeyyLfbkwJgNQAAAAAAAAAAK7PzXHWql+r7pLoBNlZ0KNYr1T7Lp5Ky7Kuu902l+K4IhMGojJgG0Yyl7Yt+FqEag2lGUfdFryjUCenKup9k9V+L4os8XOrvai/TPs+pSmRFekBXYGd9TVVz9X2yfXyWJlQAAAABybQydxVpF+uXBf8ASl8k+Zbvr5S6LhHwQGsQAMpatLu9AjuwMJWpW26/R0j3LWMVCOkUkuyRiEVCEYrklojYy0xKKmtJJNdmVWfhqpb2r2dY9i2NZxU4OL5NaMDzgMtaNrsYNMslzs/I39Wkn648H8/JSk+HduciMuj4S8DVXwCBlQhy57vGsl1S4ExybUemI/loClABpkMp6NPs9TACvR1zVlcZrk1qbFPgZio/js13fR9i1rshZFOElJPszKtzWyahCU3yitTFlkK46zkor5KnOzd/rXXqq+rf3AcjerbfUwAaQAARf4k95jVyfNx4kxx7LeuIviTR2GWg49qLXEk+zTOwhy4bzGsh1a4AUACBpkMacTeMXOSjFNt8ki1xdnwrSlalKfbohVVleJfbxhW2u74GJYt8Hxqn+kz0XIEqvPRxr5v+qbfgW4l1S1nBpd+Z6Ew0KPNaPuZWvXmW+XgQsTlV6Z9ujKqcZQk4yTTXPUqNQAEXOy1pir5k2dhDiQ3eNXF8/p4kxloDAAocync5Eo6cHxj4IC62hju+rWH9keK+fgpjWIttm4yrrVsknOS4fCO4qMHN3P8AHa9YdH2LZNNJpppmdVkAAAAAOHaeOrK3bH3RXH5R2yaim20kurKjPzXdrXVr9C5vuXBxE+HTv8iMftXGXggLrZ+NuKtZe+XP4+C6jrABlQAACu2hhfW97SvV90V1+UWIA82S4+Tbjv0S1j+L5FplYNd+sl6Z9118lZfi3U++OsfyjxRpFhVtOqS/kjKD780TrLx5croft6FCBCr6WXjx53Q/T1Oe3adS4Vpzf+kVRgQqfIybch+uXp/FciAnoxLr/bHSP5PgizxcGuh/U/VPv28AQ4GF9LVty9X2xfT5LEAyoAAAAAAAAAAIbMWizjOqLffkQvZ2O+kl4kdgA41s7HXNSfmRNXi0V+2qKffQmAAAAAAAAAH/2Q==");
    background-size: cover;
    width: 40px;
    height: 40px;
    button {
      width: 40px;
      height: 40px;
    }
  }
}
