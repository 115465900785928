.error-to-fix-modal {
  .custom-select {
    width: 180px;
  }
  .content {
    text-align: center;
    .notification {
      position: relative;
      top: -15px;
    }
    .footer {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .buttons {
      display: flex;
      justify-content: center;
      button {
        width: 145px;
      }
    }
  }
}
