.empty-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed;
  padding: 40px;
  .buttons {
    display: flex;
  }
}
