@import "../../../assets/styles/variables";

#modal {
display: unset;
background-color: black;
background-color: rgba(0, 0, 0, 0.4);
-webkit-transition: 0.5s;
transition: all 0.3s linear;
overflow: unset;

  .modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal-subtitle {
      color: $primary;
      background-color: $bg_light;
    }
  }

  .modal-dialog {
    .modal-content {
      max-height: 100%;
      position: absolute;

      .modal-footer {
        justify-content: center;
      }
      
      .modal-body {
        overflow-y: auto;

        .center {
          text-align: center;
        }
      }
    }
    &.small {
      max-width: 300px;
    }
    &.medium {
      max-width: 520px;
    }
    &.large {
      max-width: 700px;
    }
  }
}
