@import "../../../assets/styles/variables";
form {
  padding: 20px;
  color: $form_color;
  .company-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .company-id {
      display: flex;
      align-items: center;
    }
  }
  .right {
    text-align: right;
  }
  .fiscal-period {
    display: flex;
  }
}
