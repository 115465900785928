@import "../../assets/styles/variables";

.companies-page {
  .back-to {
    padding-top: 10px;
    display: flex;
    align-items: center;
    a {
      font-size: 15px;
    }
  }
}

