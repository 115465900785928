.header-actions {
  padding-bottom: 20px;
  padding-right: 10px;
  #modal.upload-gl-modal .modal-content {
    .modal-body.center {
      overflow: unset;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .select-wrapper {
      display: flex;
      select {
        width: 180px;
      }
    }
  }
}
