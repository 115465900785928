@import "../../../assets/styles/variables";

.page-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url("../../../assets/images/bg.svg");
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.page-wrapper {
  width: 450px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
}

.page {
  color: #6B7280;
  padding: 20px 40px;
  &-header {
    color: #000;
    margin: 0;
    padding-bottom: 15px;
  }

  &-footer {
    color: #6B7280;
    font-size: 14px;
    padding: 10px 40px;
    border-top: 2px solid $grey;
    background: #F9FAFB;
    a {
      text-decoration: none;
    }
  }
}
