.control-center {
  padding: 30px 30px;
  display: flex;
  justify-content: space-between;
  .card {
    width: 30%;
  }
  .methods {
    display: flex;
    flex-direction: column;
    select {
      max-width: 400px;
    }
    .form-check {
      input {
        min-width: 1em;
      }
      display: flex;
      align-items: center;
      .label {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
