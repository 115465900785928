@import "../../assets/styles/variables";

.company-tabs {
  #companyPartsTabs, #companyTypeTabs {
    button {
      color: grey;
    }
    .active {
      border: none;
      border-bottom: 2px solid;
      color: $primary;
    }
  }
  .tab-content .active {
    border: unset;
  }
}

