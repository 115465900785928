@import "../../../assets/styles/variables";

.page-layout {
    height: $content_height;
    &.header-small {
        height: $content_height_smallHeader;
    }
    .header {
        h4 {
            color: $primary;
        }
        padding-bottom: 10px;
        margin: 20px;
        display: flex;
        justify-content: space-between;
    }
}
