@import "../../assets/styles/variables";

main {
  height: $content_height;
  &.small-header {
    height: $content_height_smallHeader;
  }
  .row, .col {
    height: 100%;
  }
}
