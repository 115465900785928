.period-controls {
  display: flex;
  flex-direction: column;
  .control {
    display: flex;
    button {
      width: 80%;
    }
  }
}
