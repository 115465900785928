.error-modal {
  #modal {
    .modal-dialog .modal-content .modal-body.center {
      overflow: unset;
      overflow-y: scroll;
    }
  }
  .content {
    overflow: unset;
  }
  .footer {
    display: flex;
    flex-direction: column;
    .title {
      padding: 20px 0;
    }
  };
}
