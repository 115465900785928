// todo move to mixin
#grant-form {
  .form-header {
    display: flex;
    justify-content: flex-end;
  }
  .grant {
    .phone {
      display: flex;
      flex-direction: column;
    }
    .period {
      display: flex;
    }
    .labels {
      display: flex;
      justify-content: space-between;
    }

  }
}
