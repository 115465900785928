@import "../../../assets/styles/variables";

.navbar {
  height: $header_height;
  background-color: $light_grey;
  .nav-link {
    color: $dark_blue;
    border-radius: 5px;
    &:hover {
      background-color: $light_blue;;
    }
    &.active {
      background-color: $light_blue;;
    }
  }
}
