@import "../../../assets/styles/variables";
.dashboard {
  .container-fluid {
    justify-content: space-between;
    display: flex;
    .half-container {
      width: 48%;
    }
    .red-text-programs {
      :nth-child(2),
      :nth-child(5), {
        color: #c50505;
      }
    }
    .red-text-grants {
      :nth-child(2),
      :nth-child(3),
      :nth-child(6), {
        color: #c50505;
      }
    }
  }

  .header {
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
    .actions {
      display: flex;
    }
  }
}
