@import "../../../assets/styles/variables";

.user-id {
  display: flex;
  align-items: center;
  #id {
    color: $white;
    max-width: 120px;
    background: $primary;
  }
}
