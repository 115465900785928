// ----- BORDER -----

// ----- BREAKPOINTS -----

// ----- COLOR -----
$white: #fff;
$black: #000;
$grey: #E5E7EB;
$primary: #5F56E9;
$bg_dark: #1F2937;
$bg_light: #CCE0FF;
$form_color: #6B7280;
$light_grey: #F0F0F0;
$dark_blue: #083F75;
$light_blue: #D0DDED;
$red: red;
$header_height: 70px;
$content_height: calc(100% - 125px);
$content_height_smallHeader: calc(100% - 55px);
$success: #D4EFDA;
$error: #FFB8B8;
$info: #D8EAFF;

// ----- GRIDS -----

// ----- MOTION -----

// ----- OPACITY -----

// ----- SHADOWS -----

// ----- SPACING -----
