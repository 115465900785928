.grant-budget {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .new-line {
    .dropdown-toggle {
      &:after {
        content: none;
      }
    }
  }
  table {
    thead {
      p {
        margin: 0;
      }
    }
  }
}
