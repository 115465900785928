header.company-header {
  height: 80px;
  padding: 0 10px;
}
.nav {
  padding-bottom: 20px;
}
.content {
  height: calc(100% - 180px);
}
