.document-settings-page {
  padding: 0 20px;
  header {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }
  .content {
    .options {
      height: 260px;
      width: 190px;
      padding: 5px 0;
      &.available,&.selected {
        border: 1.5px solid #D1D5DB;
        border-radius: 5px;
        border-style: dashed;
      }
      .disabled {
        pointer-events: none;
        opacity: 0.4;
      }
      &.controls {
        width: 100px;
      }
      .option {
        cursor: pointer;
        padding: 10px;
        &.active {
          background: #D1D5DB;
          border: unset;
        }
      }
    }
    .icon {
      transition: opacity 0.4s ease;
      font-size: xx-large;
      color: #1357A6;
    }
  }
}
