@import "../../../assets/styles/variables";
.company-id {
  .id-input {
    color: $white;
    max-width: 120px;
    background: $primary;
    padding: 0 4px;
    border-radius: 4px;
  }
}
