.forgot-password {
    text-align: right;
    a {
        text-decoration: none;
    }
}
.count {
    text-align: end;
    a {
        text-decoration: none;
    }
}
