@import "../../../assets/styles/variables";

.back-to {
  cursor: pointer;
  display: flex;
  a {
    color: $primary;
    text-decoration: none;
    font-weight: bold;
  }
}
