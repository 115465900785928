.gl-accounts {
  .empty-page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed;
    flex-direction: column;
    .buttons {
      display: flex;
    }
  }
  .empty-table {
    display: flex;
    height: 50%;
  }
  height: 100%;
  .table-wrapper {
    height: 100%;
    table {
      th {
        min-width: 140px;
      }
    }
  }
}

