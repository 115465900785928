.payroll-register {
  .empty-page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .buttons {
      display: flex;
    }
  }
  .empty-table {
    height: 50%;
    display: flex;
  }
  height: 100%;
  >div {
    height: 100%;
  }
}
