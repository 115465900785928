@import "../../../assets/styles/variables";
.user-form {
  padding: 20px;
  width: 30%;
  color: $form_color;
  .right {
    display: flex;
    justify-content: flex-end;
  }
}
