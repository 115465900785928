.user-form-modal {
  .user-form {
    width: 100%;
  }
}
.header {
  display: flex;
  justify-content: flex-end;
}

