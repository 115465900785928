.upload-result {
  .header {
    flex-direction: column;
    p {
      margin: 0;
      padding: 0;
    }
  }
  .result-content {
    padding: 20px;
    .item {
      display: flex;
      justify-content: space-between;
      .item-left {
        display: flex;
      }
    }
  }
  .footer {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .buttons {
      display: flex;
      justify-content: center;
      button {
        width: 145px;
      }
    }
  }
}
